<template>
  <div class="employee">
    <div class="employee_operation">
      <el-form style="flex: 1" label-position="left" label-width="auto">
        <el-row :gutter="20">
          <el-col
            :xs="{span: 24}"
            :sm="{span: 12}"
            :md="{span: 6}"
            :lg="{span: 6}"
            :xl="{span: 6}"
          >
            <el-form-item label="用户姓名">
              <el-input
                v-model="filter.username"
                placeholder="请输入用户姓名"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="{span: 24}"
            :sm="{span: 12}"
            :md="{span: 6}"
            :lg="{span: 6}"
            :xl="{span: 6}"
          >
            <el-form-item label="用户代码/工号">
              <el-input
                v-model="filter.id"
                placeholder="请输入用户代码/工号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="{span: 24}"
            :sm="{span: 12}"
            :md="{span: 6}"
            :lg="{span: 6}"
            :xl="{span: 6}"
          >
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleAdd"
            >搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-button
        type="primary"
        plain
        icon="el-icon-plus"
        @click="handleAdd"
      >新增用户</el-button>
    </div>
    <el-table
      :data="records"
      row-key="id"
      header-cell-class-name="employee_table-header"
      border
    >
      <el-table-column label="用户代码/工号" prop="name"></el-table-column>
      <el-table-column label="用户姓名" prop="code"></el-table-column>
      <el-table-column label="职位" prop="title"></el-table-column>
      <el-table-column label="组织代码" prop="effectiveDate"></el-table-column>
      <el-table-column label="组织名称" prop="departmentTitle"></el-table-column>
      <el-table-column label="账号名" prop="effectiveDate"></el-table-column>
      <el-table-column align="right">
        <template #default="scope">
          <el-button
            size="mini"
            plain
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="slot, sizes, prev, pager, next, jumper"
      :total="total"
      background
      class="sf-pagination"
    >
      <slot>
        <div class="total-page">{{`共${total}条记录 第${currentPage}/${totalPage}页`}}</div>
        <div style="flex:1"></div>
      </slot>
    </el-pagination>
    <el-dialog
      title="新增人员"
      v-model="addEmployeePopupVisible"
    >
        <el-form :model="employee" :rules="rules" label-position="top">
        <div>
          <div class="popupContainer">
            <div class="popupItem">
              <el-form-item label="用户名称" prop="name" >
                <el-input v-model="employee.name"></el-input>
              </el-form-item>
            </div>
          <div class="popupItem">
            <el-form-item label="职位名称" prop="title">
              <el-input v-model="employee.title"></el-input>
            </el-form-item>
          </div>
          </div>
            <div class="popupContainer">
              <div class="popupItem">
                <el-form-item label="所属组织" class="nonInput" prop="departmentTitle">
                  <el-input 
                    placeholder="选择组织"
                    clearable
                    readonly  
                    v-model="employee.departmentTitle" 
                    @click="handleSelectDepartment">
                  </el-input>
                </el-form-item>
              </div>
              <div class="popupItem" >
                <el-form-item class="nonInput" label="生效时间" prop="effectiveDate">
                  <el-date-picker
                    class="nonInput"
                    v-model="employee.effectiveDate"
                    type="date"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="addEmployeePopupVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmEdit">确 定</el-button>
          </span>
        </template>
    </el-dialog>
    <!-- <el-dialog
      title="选择部门"
      v-model="departmentsTreePopupVisible"
    >
      <DepartmentsTree
        :records="departments"
        :onSelect="handleDepartmentSelect"
      />
    </el-dialog> -->
    <DepartmentsTree
    :onSelect="handleDepartmentSelect"
    :visible="departmentsTreePopupVisible"
    :onClose="() => departmentsTreePopupVisible = false"
  />
  </div>
</template>

<script>
import { employees } from '../mockdata';
import departmentsTree from '../components/departmentsTree';
import { departments } from '../mockdata';
export default {
  data() {
    return {
      records: employees,
      total: 0,
      totalPage: 0,
      currentPage: 1,
      pageSize: 20, 
      loading: true,
      filter: {
        username: '',
        id: '',
        departmentCode: '',
      },
      addEmployeePopupVisible: false,
      employee: {
        username: '',
        id: '',
        name: '',
        departmentCode: '',
        departmentTitle: '',
        title: '',
        effectiveDate: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入用户姓名',
            trigger: 'blur'
          }
        ],
        title: [
          {
            required: true,
            message: '请输入用户职位',
            trigger: 'blur'
          }
        ],
        departmentTitle: [
          {
            required: true,
            message: '请选择所属组织',
            trigger: 'blur'
          }
        ],
        effectiveDate: [
          {
            required: true,
            message: '请选择生效日期',
            trigger: 'blur'
          }
        ]
      },
      departmentsTreePopupVisible: false,
      departments: departments,
    }
  },
  components: {
    DepartmentsTree: departmentsTree,
  },
  mounted() {
    this.getData();
    this.$store.dispatch('organization/getDepartments')
  },
  methods: {
    getData() {},
    handleCurrentChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getData();
    },
    handleAdd() {
      this.employee = {};
      this.addEmployeePopupVisible = true;
    },
    handleDelete(row) {
      console.log('delete', row)
      // const current = this.records.find((item)=>item.id==this.employee.id);
      this.records.splice(row,1);
    },
    handleEdit(row) {
      console.log('edit', this.records[row])
      this.addEmployeePopupVisible = true;
      this.employee = this.records[row]
      console.log('edit', this.employee)
    },
    handleSelectDepartment(){
      this.departmentsTreePopupVisible = true;
    },
    handleDepartmentSelect({ code, name }) {
      console.log('select', code + name);
      const current = this.records.find((item)=>item.id==this.employee.id);
      if(current == null){
        this.employee.departmentCode = code;
        this.employee.departmentTitle = name;
      }else{
        current.departmentCode = code;
        current.departmentTitle = name;
      }
      this.departmentsTreePopupVisible = false;
    },
    confirmEdit(){
      if(!this.employee.name || !this.employee.departmentTitle || !this.employee.title || !this.employee.effectiveDate ){
        alert("请完善员工信息");
        return;
      }
      if(!this.employee.id){
        this.records.push(this.employee);
        this.employee = {};
        this.addEmployeePopupVisible = false;
      }
    }
  }
}
</script>

<style lang="scss">
.employee {
  padding: $padding;
  border-radius: 0 $border-radius $border-radius $border-radius;
  @include background-color-content();
  &_operation {
    padding-bottom: $padding-xxl;
    display: flex;
    align-items: flex-start;
  }
  &_table-header {
  background-color: transparent !important;
}
}
.popupContainer {
  display: flex;
}
.popupItem {
  flex: 1;
  margin-right:30px;
  justify-content: flex-end;
}
.nonInput {
  // margin-top: 10px;
}
</style>