<template>
  <!-- eslint-disable -->
  <el-dialog
    title="选择部门"
    v-model="visible"
    @closed="onClose"
  >
    <div class="department-tree">
      <el-input
        v-model="search"
        placeholder="请输入关键字搜索"
        clearable
        class="department-tree_search"
      />
      <el-tree
        :data="displayedRecords"
        node-key="code"
        default-expand-all
      >
        <template #default="{ node }">
          <span class="department-tree_node">
            <p>
              {{ node.data.name }}
              <span class="department-tree_node-code">
                ({{ node.data.code }})
              </span>
            </p>
            <span>
              <el-button
                type="text"
                size="mini"
                @click.stop="onSelect(node.data)">
                选择
              </el-button>
            </span>
          </span>
        </template>
      </el-tree>
    </div>
  </el-dialog>
</template>

<script>
import { filterDepartment } from '../utils';
export default {
  data() {
    return {
      search: "",
    }
  },
  props: {
    visible: Boolean,
    onSelect: Function,
  },
  computed: {
    displayedRecords() {
      return filterDepartment(this.search, this.$store.state.organization.departments);
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.department-tree {
  &_search {
    margin-bottom: $padding-l;
  }
  &_node {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-code {
      @include color-secondary();
      font-size: 12px;
    }
  }
}
</style>